import { useSearchParams } from "src/router/useSearchParams"

export function useBulkAddRentalUnitsDialogParams() {
  const params = useSearchParams({
    keys: [
      { key: "dialogOpen", type: "boolean" },
      { key: "bulkSearch", type: "string" },
      { key: "bulkOffset", type: "number" },
    ],
  })

  return {
    dialogOpen: !!params.searchParams.dialogOpen,
    setDialog: (open: boolean) => params.setSearchParams("dialogOpen", open),

    search: params.searchParams.bulkSearch ?? "",
    setSearch: (search: string) =>
      params.setSearchParams("bulkSearch", search || null),

    offset: params.searchParams.bulkOffset ?? 0,
    setOffset: (offset: number) => params.setSearchParams("bulkOffset", offset),
  }
}

import {
  IndoorNoiseThresholdInput,
  OutdoorNoiseThresholdInput,
} from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseThresholdsDialog/NoiseThresholdInputs"
import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { useTranslate } from "src/i18n/useTranslate"

export function SectionNoiseThreshold({
  indoorValue,
  setIndoorValue,
  outdoorValue,
  setOutdoorValue,
}: {
  indoorValue: number
  setIndoorValue: (v: number) => void
  outdoorValue: number
  setOutdoorValue: (v: number) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <PresetSection
      title={t(langKeys.noise_thresholds)}
      description={t(langKeys.noise_monitoring_short_description)}
      infoTooltipContents={
        <>
          <p>{t(langKeys.noise_monitoring_threshold_guide_title)}</p>
          <p>{t(langKeys.noise_monitoring_unit_description)}</p>
          {t(langKeys.noise_monitoring_threshold_guide_body)
            .split("\n")
            .filter(Boolean)
            .map((item, i) => (
              <p key={i}>{item}</p>
            ))}
        </>
      }
    >
      <IndoorNoiseThresholdInput
        value={indoorValue}
        setValue={setIndoorValue}
      />

      <OutdoorNoiseThresholdInput
        value={outdoorValue}
        setValue={setOutdoorValue}
      />
    </PresetSection>
  )
}

import styled from "styled-components"

import { NoHomesInPreset } from "src/components/EmptyState/NoHomesInPreset"
import { SearchFilter } from "src/components/Filter/SearchFilter"
import { BulkAddRentalUnitsDialog } from "src/components/MonitoringPresets/MonitoringPresetDialogs/BulkAddRentalUnitsDialog/BulkAddRentalUnitsDialog"
import { useBulkAddRentalUnitsDialogParams } from "src/components/MonitoringPresets/MonitoringPresetDialogs/BulkAddRentalUnitsDialog/useBulkAddRentalUnitsDialogParams"
import { Pager } from "src/components/Pager/Pager"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useSearchParams } from "src/router/useSearchParams"
import { MButton } from "src/ui/Button/MButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

const LIMIT = 30

export function MonitoringPresetsNoiseRentalUnits({
  presetId,
  presetName,
  nbrUnits,
}: {
  presetId: string
  presetName: string
  nbrUnits: number
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const { name, setName, offset, setOffset } = useRentalUnitsFilters()
  const dialog = useBulkAddRentalUnitsDialogParams()

  const presetHomes = useFetchHomes({
    orgId,
    filters: {
      noise_profile_id: presetId,
      limit: LIMIT,
      name,
      offset,
    },
  })
  const totalCount = presetHomes.data?.paging.total_count
  const homes = presetHomes.data?.homes

  const rows =
    homes?.map((home) => (
      <InternalLink key={home.home_id} to={Routes.Home.location(home.home_id)}>
        {home.name}
      </InternalLink>
    )) ?? []

  return (
    <RentalUnitsBox>
      <BulkAddRentalUnitsDialog
        presetId={presetId}
        presetName={presetName}
        open={dialog.dialogOpen}
        onClose={() => dialog.setDialog(false)}
      />

      <Show if={nbrUnits === 0}>
        <NoHomesInPreset onClick={() => dialog.setDialog(true)} />
      </Show>

      <Show if={!!nbrUnits && nbrUnits >= 1}>
        <TitleBox>
          <MText variant="heading2">{t(langKeys.home_plural)}</MText>

          <MButton onClick={() => dialog.setDialog(true)}>
            {t(langKeys.presets_add_rental_unit_to_preset)}
          </MButton>
        </TitleBox>

        <SearchBox>
          <SearchFilter
            initialValue=""
            onChange={(s) => setName(s)}
            placeholder={t(langKeys.presets_search_for_unit_name)}
          />
        </SearchBox>

        <GridTable
          header={[<div key="title">{t(langKeys.home)}</div>]}
          rows={rows}
          mobileRows={rows}
        />

        <Pager
          limit={LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={totalCount}
        />
      </Show>
    </RentalUnitsBox>
  )
}

const RentalUnitsBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.M};
  flex-wrap: wrap;
`

const SearchBox = styled.div`
  max-width: 25ch;
`

function useRentalUnitsFilters() {
  const params = useSearchParams({
    keys: [
      { key: "offset", type: "number" },
      { key: "name", type: "string" },
    ],
  })

  return {
    name: params.searchParams.name ?? undefined,
    setName: (name: string) => {
      // Offset is reset to ensure that the user is taken back to the first page on name change
      params.setSearchParamsAll({ name, offset: 0 })
    },

    offset: params.searchParams.offset ?? 0,
    setOffset: (n: number) => params.setSearchParams("offset", n),
  }
}

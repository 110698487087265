import { PresetSection } from "src/components/MonitoringPresets/MonitoringPresetDialogs/PresetSection"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelect } from "src/ui/MSelect/MSelect"

export function SectionNoiseDuration({
  value,
  setValue,
}: {
  value: number
  setValue: (v: number) => void
}) {
  const { t, langKeys } = useTranslate()
  const minutesStr = (number: number) =>
    t(langKeys.number_of_minutes, { number, count: number })

  return (
    <PresetSection
      title={t(langKeys.presets_noise_duration_title)}
      description={t(langKeys.presets_noise_duration_description)}
    >
      <MSelect
        required
        label={t(langKeys.presets_noise_duration_title)}
        value={String(value)}
        options={[
          { label: minutesStr(5), value: String(5 * 60) },
          { label: minutesStr(10), value: String(10 * 60) },
          { label: minutesStr(15), value: String(15 * 60) },
        ]}
        onChange={(value) => setValue(Number(value))}
      />
    </PresetSection>
  )
}

import styled from "styled-components"

import { MonitoringPresetsNoiseRentalUnits } from "src/components/MonitoringPresets/MonitoringPresetsNoiseRentalUnits"
import { MonitoringPresetsNoiseSettings } from "src/components/MonitoringPresets/MonitoringPresetsNoiseSettings"
import { useMonitoringPresetBreadcrumbs } from "src/components/MonitoringPresets/useMonitoringPresetBreadcrumbs"
import { RouteNotFound } from "src/components/RouteNotFound/RouteNotFound"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchNoiseMonitoringPreset,
  usePatchNoiseMonitoringPreset,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Editable } from "src/ui/Editable/Editable"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function MonitoringPresetsNoiseEdit({ presetId }: { presetId: string }) {
  const { t, langKeys } = useTranslate()
  const { orgId, orgAccessLogic } = useOrganization()
  const hasAdminAccess = orgAccessLogic.hasAdminAccess
  const fetchNoiseMonitoringPreset = useFetchNoiseMonitoringPreset({
    orgId,
    noisePresetId: presetId,
  })
  const breadcrumbs = useMonitoringPresetBreadcrumbs({
    editName: fetchNoiseMonitoringPreset.data?.name ?? "...",
  })

  const patchPreset = usePatchNoiseMonitoringPreset()

  if (fetchNoiseMonitoringPreset.isError) {
    return <RouteNotFound />
  }

  const presetData = fetchNoiseMonitoringPreset.data
  const numberHomes = fetchNoiseMonitoringPreset.data?.number_of_homes
  const title = fetchNoiseMonitoringPreset.isLoading ? (
    <MSkeleton width={300} />
  ) : (
    <Editable onSave={handleUpdateName} disabled={!hasAdminAccess}>
      {fetchNoiseMonitoringPreset.data.name}
    </Editable>
  )
  const description = fetchNoiseMonitoringPreset.isLoading ? (
    <MSkeleton width={200} />
  ) : (
    t(langKeys.presets_apply_to_rental_units, {
      number: numberHomes,
      count: numberHomes,
    })
  )

  const tabs: ITab[] = [
    {
      label: t(langKeys.settings),
      id: "settings",
      view: presetData ? (
        <MonitoringPresetsNoiseSettings presetData={presetData} />
      ) : null,
    },
    {
      label:
        t(langKeys.home, { count: 10 }) +
        (numberHomes != null ? ` (${numberHomes})` : ""),
      id: "rental_units",
      view: presetData ? (
        <MonitoringPresetsNoiseRentalUnits
          presetName={presetData.name}
          presetId={presetData.id}
          nbrUnits={numberHomes ?? 0}
        />
      ) : null,
    },
  ]

  async function handleUpdateName(name: string) {
    await patchPreset.mutateAsync({
      path: { organization_id: orgId, profile_id: presetId },
      body: { name },
    })
  }

  return (
    <BreadcrumbView
      breadcrumbs={breadcrumbs.noiseEdit}
      title={title}
      description={description}
    >
      <Tabs labels={tabs}>
        {tabs.map(({ id, view }) => (
          <TabPanel key={id}>
            <TabContentBox>{view}</TabContentBox>
          </TabPanel>
        ))}
      </Tabs>
    </BreadcrumbView>
  )
}

const TabContentBox = styled.div`
  margin: ${spacing.XL2} 0;
`

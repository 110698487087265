import { useTranslate } from "src/i18n/useTranslate"
import { MTextField } from "src/ui/MTextField/MTextField"

const NOISE_THRESHOLD_MIN = 60
const NOISE_THRESHOLD_MAX = 120

export function IndoorNoiseThresholdInput({
  value,
  setValue,
}: {
  value: number
  setValue: (v: number) => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <MTextField
      label={`${t(langKeys.indoor_noise)} (${t(langKeys.decibel_label_short)})`}
      type="number"
      value={String(value)}
      onChange={(v) => setValue(Number(v))}
      min={NOISE_THRESHOLD_MIN}
      max={NOISE_THRESHOLD_MAX}
    />
  )
}

export function OutdoorNoiseThresholdInput({
  value,
  setValue,
}: {
  value: number
  setValue: (v: number) => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <MTextField
      label={`${t(langKeys.outdoor_noise)} (${t(langKeys.decibel_label_short)})`}
      type="number"
      value={String(value)}
      onChange={(v) => setValue(Number(v))}
      min={NOISE_THRESHOLD_MIN}
      max={NOISE_THRESHOLD_MAX}
    />
  )
}

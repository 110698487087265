import { useState } from "react"

import { PresetSettingCard } from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCard"
import { SecurityAlarmDialog } from "src/components/MonitoringPresets/MonitoringPresetDialogs/SecurityAlarmDialog/SecurityAlarmDialog"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"

export function PresetSettingCardSecurityAlarm({
  noisePreset,
}: {
  noisePreset: TNoiseMonitoringPreset
}) {
  const { t, langKeys } = useTranslate()
  const { grace_period, instantly_turn_on } = noisePreset.security_alarm

  const [manageOpen, setManageOpen] = useState(false)

  return (
    <PresetSettingCard
      title={t(langKeys.presets_security_alarm_settings_title)}
      description={t(langKeys.presets_security_alarm_settings_body)}
      labeledPills={[
        {
          label: t(langKeys.security_alarm_grace_period_title),
          id: "grace-period",
          isOn: true,
          value: `${grace_period} ${t(langKeys.presets_seconds_shorthand)}`,
        },
        {
          label: t(langKeys.instantly_turn_on_alarm_title),
          id: "instantly-turn-on-alarm",
          isOn: instantly_turn_on,
        },
      ]}
      onManageClick={() => setManageOpen(true)}
    >
      <SecurityAlarmDialog
        open={manageOpen}
        onClose={() => setManageOpen(false)}
        noisePreset={noisePreset}
      />
    </PresetSettingCard>
  )
}

import { useState } from "react"

import { PresetSettingCard } from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCard"
import { NoiseThreholdsDialog } from "src/components/MonitoringPresets/MonitoringPresetDialogs/NoiseThresholdsDialog/NoiseThresholdsDialog"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { formatTimeString } from "src/utils/l10n"

type PresetSettingCardNoiseThresholdsProps = {
  presetData: TNoiseMonitoringPreset
}

export function PresetSettingCardNoiseThresholds({
  presetData,
}: PresetSettingCardNoiseThresholdsProps) {
  const { t, langKeys } = useTranslate()

  const [manageOpen, setManageOpen] = useState(false)

  const thresholdData = useGetThresholdData(presetData)

  return (
    <PresetSettingCard
      title={t(langKeys.presets_thresholds_and_duration_title)}
      description={t(langKeys.presets_thresholds_and_duration_description)}
      labeledPills={[
        {
          label: t(langKeys.indoor_noise),
          id: "indoor-noise",
          isOn: true,
          value: thresholdData.indoorNoiseThreshold,
        },
        {
          label: t(langKeys.outdoor_noise),
          id: "outdoor-noise",
          isOn: true,
          value: thresholdData.outdoorNoiseThreshold,
        },
        {
          label: t(langKeys.duration),
          id: "duration",
          isOn: true,
          value: thresholdData.indoorNoiseDuration,
        },
        {
          label: t(langKeys.quiet_hours),
          id: "quiet-hours",
          isOn: thresholdData.indoorQuietHoursEnabled,
          get tooltipData() {
            return this.isOn
              ? [
                  ...thresholdData.indoorQuietHourData,
                  ...thresholdData.outdoorQuietHourData,
                ]
              : undefined
          },
        },
      ]}
      onManageClick={() => setManageOpen(true)}
    >
      <NoiseThreholdsDialog
        open={manageOpen}
        onClose={() => setManageOpen(false)}
        settings={presetData}
      />
    </PresetSettingCard>
  )
}

function useGetThresholdData(d: TNoiseMonitoringPreset) {
  const { t, langKeys } = useTranslate()
  const { clock_type } = useGetUser()

  const indoorThresholds = d.indoor_noise_threshold
  const outdoorThresholds = d.outdoor_noise_threshold

  const dB = t(langKeys.decibel_label_short)
  const minutesShorthand = t(langKeys.presets_minutes_shorthand)
  const IndoorNoise = t(langKeys.indoor_noise)
  const OutdoorNoise = t(langKeys.outdoor_noise)

  return {
    indoorNoiseThreshold: indoorThresholds?.threshold
      ? `${indoorThresholds.threshold} ${dB}`
      : "",
    indoorNoiseDuration: indoorThresholds?.duration_seconds
      ? `${secondsInFullMinutes(indoorThresholds.duration_seconds)}
        ${minutesShorthand}`
      : "",
    indoorQuietHoursEnabled: !!indoorThresholds?.quiet_hours_enabled,
    indoorQuietHourData: indoorThresholds?.quiet_hours
      ? [
          `${t(langKeys.time)}: ${formatTimeString(
            indoorThresholds.quiet_hours.start_time_of_day,
            clock_type
          )} - ${formatTimeString(
            indoorThresholds.quiet_hours.end_time_of_day,
            clock_type
          )}`,
          `${IndoorNoise}: ${indoorThresholds.quiet_hours.threshold} ${dB}`,
        ]
      : [],

    outdoorNoiseThreshold: outdoorThresholds?.threshold
      ? `${outdoorThresholds.threshold} ${dB}`
      : "",
    // NB: The design does not consider that duration can be different for
    // indoor & outdoor, while the BE supports this. For now we will use
    // indoor, but that might change in the future.
    outdoorNoiseDuration: outdoorThresholds?.duration_seconds
      ? `${secondsInFullMinutes(outdoorThresholds.duration_seconds)}
        ${minutesShorthand}`
      : "",
    // NB: The design does not consider that quiet hours can be different for
    // indoor & outdoor, while the BE supports this. For now we will use indoor,
    // but that might change in the future.
    outdoorQuietHourData: outdoorThresholds?.quiet_hours
      ? [
          // `Time: ${formatTimeString(
          //   outdoorThresholds.quiet_hours.start_time_of_day,
          //   clock_type
          // )} - ${formatTimeString(
          //   outdoorThresholds.quiet_hours.end_time_of_day,
          //   clock_type
          // )}`,
          `${OutdoorNoise}: ${outdoorThresholds.quiet_hours.threshold} ${dB}`,
        ]
      : [],
  }
}

function secondsInFullMinutes(s: number) {
  return (s / 60).toFixed(0)
}

import styled from "styled-components"

import { PresetSettingCardNoiseAlert } from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseAlert"
import { PresetSettingCardNoiseThresholds } from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCardNoiseThresholds"
import { PresetSettingCardSecurityAlarm } from "src/components/MonitoringPresets/MonitoringPresetCards/PresetSettingCardSecurityAlarm"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { TNoiseMonitoringPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type MonitoringPresetsNoiseSettingsProps = {
  hidden?: boolean
  presetData: TNoiseMonitoringPreset
}

export function MonitoringPresetsNoiseSettings({
  hidden,
  presetData,
}: MonitoringPresetsNoiseSettingsProps) {
  const { show_security_settings_in_noise_profile: showSecurityAlarm } =
    useBackendFlags()

  const { t, langKeys } = useTranslate()
  const variants = ["first_alert", "second_alert", "third_alert"] as const

  if (hidden) return null

  return (
    <MonitoringPresetsNoiseSettingsBox>
      <CardSection title={t(langKeys.noise_thresholds)}>
        <PresetSettingCardNoiseThresholds presetData={presetData} />
      </CardSection>

      <CardSection title={t(langKeys.settings_noise_alerts_title)}>
        {variants.map((variant) => {
          return (
            <PresetSettingCardNoiseAlert
              key={variant}
              variant={variant}
              presetData={presetData}
            />
          )
        })}
      </CardSection>

      {showSecurityAlarm && (
        <CardSection title={t(langKeys.home_security_alarm_title)}>
          <PresetSettingCardSecurityAlarm noisePreset={presetData} />
        </CardSection>
      )}
    </MonitoringPresetsNoiseSettingsBox>
  )
}

const MonitoringPresetsNoiseSettingsBox = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

function CardSection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <CardsSectionBox>
      <MText variant="heading2">{title}</MText>

      <CardsContainer>{children}</CardsContainer>
    </CardsSectionBox>
  )
}

const CardsSectionBox = styled.section`
  display: grid;
  gap: ${spacing.L};
`
const CardsContainer = styled.div`
  display: grid;
  gap: ${spacing.M};
`

import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"

import NoHomesInPresetImage from "./images/no-homes-in-preset.svg"

export function NoHomesInPreset({ onClick }: { onClick: () => void }) {
  const { t, langKeys } = useTranslate()
  const title = t(langKeys.presets_no_homes_empty_state_title)
  const description = t(langKeys.presets_no_homes_empty_state_description)
  const buttonText = t(langKeys.presets_no_homes_empty_state_button_add)

  return (
    <EmptyStateWithImage
      Image={NoHomesInPresetImage}
      title={title}
      description={description}
      content={<MButton onClick={onClick}>{buttonText}</MButton>}
    />
  )
}
